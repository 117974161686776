import Cookies from 'js-cookie';
import { SETTINGS_SMS_SEGEMENT_LENGTH, UX_TENANT, PERSON_STATES } from '@/utils/Constants';
import moment from 'moment';

export const currentLocalTime = () => {
  const currentDate = new Date();
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  return hours + ':' + minutes;
};

export const convertToUTC = (date: moment.MomentInput, format = 'YYYY-MM-DDTHH:mm:ss') => {
  const localDate = moment(date).format('YYYY-MM-DD HH:mm');
  return moment.utc(new Date(localDate)).format(format).toString();
};

export const getUserFromCookie = () => {
  const userCookieData = Cookies.get('user');
  const userData = JSON.parse(userCookieData || '[]');
  // If the user data is an array, return null
  return Array.isArray(userData) ? [] : userData;
};

export const checkHasDrives = (driveList: any) => {
  let hasDrives = false;
  if (
    driveList &&
    driveList?.data !== undefined &&
    driveList?.data !== null &&
    driveList?.data?.drives !== undefined &&
    driveList?.data?.drives !== null &&
    Array.isArray(driveList?.data?.drives) &&
    driveList?.data?.drives.length > 0
  ) {
    hasDrives = true;
  }

  return hasDrives;
};

export const getModulesFromCookie = () => {
  const moduleCookieData = Cookies.get('modules');
  const moduleData = JSON.parse(moduleCookieData || '[]');
  return Array.isArray(moduleData) ? moduleData : [];
};

export const userPrivileges = () => {
  const userMenuAccess = getUserFromCookie().userPrivileges;
  const privileges: any = [];
  if (Array.isArray(userMenuAccess) && userMenuAccess.length > 0) {
    userMenuAccess.map((menu: any) => {
      if (menu.isEnabled) {
        privileges.push(menu.privilegeName);
      }
    });
  }
  return privileges;
};

export const hasModulePermission = (moduleList: any[], moduleId: string) => {
  if (moduleList && moduleList.length > 0) {
    const m = moduleList.find((i) => i.moduleId.toLowerCase() === moduleId.toLocaleLowerCase());
    if (m) {
      return true;
    }
  }
  return false;
};

export const userIsMaster = () => {
  const userCookieData = Cookies.get('user');
  const userData = JSON.parse(userCookieData || '[]');
  return userData && userData?.tenantId === UX_TENANT;
};

export const hasPrivilege = (user: any, privilegeName: string) => {
  let hasPrivilege = false;
  if (user) {
    const privileges = user.userPrivileges;
    if (privileges && Array.isArray(privileges) && privileges.length > 0) {
      const privilege = privileges.find((value) => value.privilegeName === privilegeName);
      hasPrivilege = privilege && privilege?.isEnabled === true;
    }
  }
  return hasPrivilege;
};

export const getWorkingDrive = () => {
  let workingDriveId = localStorage.getItem('workingdriveid');
  if (workingDriveId === undefined || workingDriveId === null || workingDriveId === 'undefined') {
    workingDriveId = '';
  }
  return workingDriveId;
};

export const setWorkingDrive = (workingDriveId: string | null) => {
  if (workingDriveId === undefined || workingDriveId === null || workingDriveId === 'undefined') {
    workingDriveId = '';
  }
  localStorage.setItem('workingdriveid', workingDriveId);
};

export const fileNameReplaceSplChars = (fileName: string) => {
  fileName = fileName.replace(/[/\\?%*:|"<>]/g, '-');
  return fileName;
};

export const santizeFileName = (fileName: string) => {
  if (fileName.startsWith('"')) {
    fileName = fileName.substring(1);
  }
  if (fileName.endsWith('"') && fileName.length > 1) {
    fileName = fileName.substring(0, fileName.length - 1);
  }
  return fileName;
};

export const getLocalAPIUrl = () => {
  const apiUrl = '';
  const currentUrl = window.location;

  return apiUrl;
};

export const getExtension = (fileName: any) => fileName.split('.').pop().toLowerCase();

export const calculateMessageCost = (text: string, costPerSegment: number) => {
  const segments = Math.ceil(text.length / SETTINGS_SMS_SEGEMENT_LENGTH);
  const msgcost = Math.round((segments * costPerSegment + Number.EPSILON) * 1000) / 1000;
  return msgcost;
};

export const mediaName = (fileName: string, description: string | null) =>
  description ? `${description}: ${fileName}` : fileName;

export const personIsHostile = (personStateId: string) => {
  const isHostile =
    personStateId &&
    (personStateId.toUpperCase() === PERSON_STATES.Hostile ||
      personStateId.toUpperCase() === PERSON_STATES.HostileCommunityLeader);
  return isHostile;
};

export const personIsFriendly = (personStateId: string) => {
  const isFriendly =
    personStateId &&
    (personStateId.toUpperCase() === PERSON_STATES.Friendly ||
      personStateId.toUpperCase() === PERSON_STATES.FriendlyCommunityLeader);
  return isFriendly;
};
