'use client';

import { useRouter, usePathname } from 'next/navigation';
import {
  ROUTE_DRIVE_LIST,
  ROUTE_PEOPLE_LIST,
  MSG_UNEXPECTED_ERR,
  ROUTE_CLIENT_AUTH_LOGIN,
} from '@/utils/Constants';
import { createContext, useEffect, useState } from 'react';
import { AuthContextProps, LoginProps, ResetPasswordProps } from './models';
import {
  loginUser,
  signUpUser,
  forgetPasswordUser,
  resetPasswordUser,
  logoutUser,
} from '@/hooks/useUser/query';
import Cookies from 'js-cookie';
import useNotification from '@/hooks/useNotification/useNotification';
import { hasPrivilege } from '@/utils/Common';


const defaultValues = {
  login: () => {},
  signUp: () => {},
  logout: () => {},
  isLoggedIn: () => {},
  forgetPassword: () => {},
  token: null,
  error: null,
  user: null,
  modules: null,
  response: null,
  isLoading: false,
  resetPassword: () => {},
};
const AuthContext = createContext<AuthContextProps>(defaultValues);
export function AuthProvider(props: any) {
  const { children } = props;
  const pathname = usePathname();
  const [user, setUser] = useState<any>(null);
  const [modules, setModules] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [response, setResponse] = useState<any>(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();
  const { triggerNotification } = useNotification();

  useEffect(() => {
    // check if cookie is exist for default drive
    if (
      !Cookies.get('user') &&
      !pathname.includes('/signup') &&
      !pathname.includes('/signatureverify') &&
      !pathname.includes('/signaturepayment') &&
      !pathname.includes('/paymentstatus') &&
      !pathname.includes('/reset-password')
    ) {
      router.push(ROUTE_CLIENT_AUTH_LOGIN);
    }
  }, [pathname, router]);

  /**
   * Function to register an user
   */
  const signUp = async (props: any) => {
    setIsLoading(true);
    try {
      const response: any = await signUpUser(props);
      setResponse(response);
      setError(null);
      setIsLoading(false);
    } catch (err: any) {
      const { statusText } = await err?.json();
      setError(statusText || MSG_UNEXPECTED_ERR);
      setIsLoading(false);
    }
  };

  /**
   * Function to login an user
   */
  const login = async ({ username, password, remember }: LoginProps) => {
    setIsLoading(true);
    try {
      //check if user is already logged in
      if (isLoggedIn()) {
        // Routing to the dashboard
        const luser = Cookies.get('user');
        const gotoRoute = hasPrivilege(user, 'CanManageDrives')
          ? ROUTE_DRIVE_LIST
          : ROUTE_PEOPLE_LIST;
        setTimeout(() => {
          setIsLoading(false);
          router.push(gotoRoute);
        }, 1000);
      }

      const userData: any = await loginUser({ username, password, remember });

      if (userData?.data?.user == null || userData?.data?.user == 'undefined') {
        Cookies.remove('token');
        Cookies.remove('user');
        Cookies.remove('modules');
        setError('Unauthorized');
        triggerNotification({
          title: 'Unauthorized',
          selectedColor: 'red',
        });
        setIsLoading(false);
        return;
      }

      // Setting the user here
      setUser(userData?.data?.user);
      setToken(userData?.data?.token?.accessToken);
      setModules(userData?.data?.modules);
      setError(null);
      let seconds = 3600; // 1 hour
      if (userData?.data?.token?.expiresIn && isNaN(userData?.data?.token?.expiresIn) == false) {
        seconds = userData?.data?.token?.expiresIn;
      }

      const timeout = new Date(new Date().getTime() + 1000 * seconds);

      // setting up cookie here
      Cookies.set('token', userData?.data?.token?.accessToken, {
        expires: timeout, // API return
        path: '/',
        secure:true,
        sameSite: 'strict',
      });

      Cookies.set('user',JSON.stringify(userData?.data?.user) , {
        expires: timeout, // API return
        path: '/',
        secure:true,
        sameSite: 'strict',
      });

      Cookies.set('settings', JSON.stringify(userData?.data?.settings), {
        expires: timeout, // API return
        path: '/',
        secure:true,
        sameSite: 'strict',
      });

      Cookies.set('modules', JSON.stringify(userData?.data?.modules), {
        expires: timeout, // API return
        path: '/',
        secure:true,
        sameSite: 'strict',
      });

      // Routing to the dashboard
      const gotoRoute = hasPrivilege(userData?.data?.user, 'CanManageDrives')
        ? ROUTE_DRIVE_LIST
        : ROUTE_PEOPLE_LIST;
      setTimeout(() => {
        router.refresh();
        setIsLoading(false);
        router.push(gotoRoute);
      }, 3000);
    } catch (err: any) {
      const { statusText } = await err?.json()
      setError(err.statusText || MSG_UNEXPECTED_ERR);
      triggerNotification({
        title: statusText ? statusText : 'Something went wrong !',
        selectedColor: 'red',
      })
      setIsLoading(false);
    }
  };

  /**Forget password */
  const forgetPassword = async ({ username }: LoginProps) => {
    setIsLoading(true);
    try {
      const userData: any = await forgetPasswordUser({ username });
      setError(null);
      setIsLoading(false);
      triggerNotification({
        title: userData !== undefined ? userData?.data.message : 'Success !',
        selectedColor: 'green',
      });
      setIsLoading(false);
    } catch (err: any) {
      const { statusText } = await err?.json();
      setError(statusText || MSG_UNEXPECTED_ERR);
      triggerNotification({
        title: statusText ? statusText : 'Something went wrong !',
        selectedColor: 'red',
      });
      setIsLoading(false);
    }
  };

  /** Method to reset password */
  const resetPassword = async ({ password, token }: ResetPasswordProps) => {
    setIsLoading(true);
    try {
      const response: any = await resetPasswordUser({ password, token });
      setError(null);
      setIsLoading(false);
      triggerNotification({
        title: response !== undefined ? response?.data?.message : 'Success !',
        selectedColor: 'green',
      });
      setIsLoading(false);
      // Routing to the login page
      router.push(`${ROUTE_CLIENT_AUTH_LOGIN}`);
    } catch (err: any) {
      const { statusText } = await err?.json();
      setError(statusText || MSG_UNEXPECTED_ERR);
      triggerNotification({
        title: statusText ? statusText : 'Something went wrong !',
        selectedColor: 'red',
      });
      setIsLoading(false);
    }
  };

  /**
   * Function to logout an user
   */
  const logout = async () => {
    try {
      await logoutUser();
      setUser(null);
    } catch (err) {
      setUser(null);
    } finally {
      //   router.reload()
    }
  };

  /**
   * Function to check if user is logged in
   * check cookie
   */
  const isLoggedIn = () => {
    if (Cookies.get('token') && Cookies.get('user')) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        modules,
        error,
        signUp,
        login,
        logout,
        forgetPassword,
        isLoggedIn,
        isLoading,
        token,
        response,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
